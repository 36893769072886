import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  @media print {
    display: none;
  }
  &:empty {
    display: none;
  }
`

type Props<T extends Utils.TagOrComponent> = {
  as?: T;
  children: React.ReactNode;
} & Omit<Utils.PropsOfTagOrComponent<T>, 'as'>

type PropsWithStandardRef<T extends Utils.TagOrComponent> = Props<T> & { ref?: React.Ref<Utils.ElementTypeOfTagOrComponent<T>> }

function NoPrintComponent<T extends Utils.TagOrComponent = 'div'>(
  props: Props<T>,
  ref: React.Ref<Utils.ElementTypeOfTagOrComponent<T>>,
) {
  const {
    as: Component = 'div',
    children,
    ...restProps
  } = props

  // The `any` here isn't ideal, but the dynamic prop typing makes things tricky
  return <Root as={Component} {...restProps as any} ref={ref}>{children}</Root>
}

const NoPrint: <T extends Utils.TagOrComponent = 'div'>(props: PropsWithStandardRef<T>) => JSX.Element | null = React.forwardRef(NoPrintComponent)

export default NoPrint
