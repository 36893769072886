import React, { useCallback, forwardRef, useContext } from 'react'

import TripGuard from '../../TripGuard/TripGuard'
import BookmarkCard from '../BookmarkCardNew/BookmarkCard'
import BookmarkLocation from '../BookmarkCardNew/BookmarkLocation'

import PriceDetails from 'tripPlanner/components/ItemOfferPrice/PriceDetails'
import ClickTrackerContext from 'contexts/ClickTrackerContext'
import GeoContext from 'contexts/geoContext'
import useImpressionHandler from 'hooks/useImpressionHandler'
import noop from 'lib/function/noop'
import { DistanceUnit } from 'lib/geo/distanceUnits'
import { ResponsiveImageOptions, getImageUrl } from 'lib/image/imageUtils'
import offerPageURL from 'lib/offer/offerPageURL'
import { addQueryParamsToPath } from 'lib/url/searchUrlUtils'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/BookmarkButton'
export interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  distance?: number | null
  distanceFrom?: string | null
  distanceUnit?: DistanceUnit
  linkQuery?: URLSearchParams
  onClick?: (offer: App.BedbankOffer | App.BedbankOfferSummary) => void
  aspectRatio?: string
  source?: string
  target?: '_blank'
  className?: string
}

const IMAGE_PARAMS: ResponsiveImageOptions = {
  width: '400px',
}

const BedbankCard = forwardRef<HTMLAnchorElement, Props>(function BedbankCard(
  {
    linkQuery,
    offer,
    distance,
    distanceFrom,
    distanceUnit,
    onClick = noop,
    aspectRatio,
    source,
    target,
    className,
  }: Props,
  ref,
) {
  const { image } = offer
  const imageUrl =
    image.url ??
    (image.id &&
      getImageUrl(
        image.id,
        aspectRatio ? { aspectRatio, ...IMAGE_PARAMS } : IMAGE_PARAMS,
      ))
  const location = [
    offer.property.address.city,
    offer.property.address.countryName,
  ]
    .filter(Boolean)
    .join(', ')

  const { currentCurrency } = useContext(GeoContext)

  const impressionHandlerRef = useImpressionHandler(offer.id)

  const clickTracker = useContext(ClickTrackerContext)

  const onCardClick = useCallback(() => {
    clickTracker(offer.id)
    onClick(offer)
  }, [clickTracker, offer, onClick])

  const offerUrl = addQueryParamsToPath(offerPageURL(offer, linkQuery), {
    source,
  })

  return (
    <BookmarkCard
      className={className}
      ref={(element) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(element)
          } else {
            ref.current = element
          }
        }
        impressionHandlerRef(element)
      }}
      title={offer.name}
      body={offer.amenities}
      bodyLines={3}
      bookmarkButton={
        <TripGuard>
          <BookmarkButton offer={offer} />
        </TripGuard>
      }
      distance={distance}
      distanceFrom={distanceFrom}
      distanceUnit={distanceUnit}
      location={<BookmarkLocation startLocation={location} />}
      imageUrl={imageUrl}
      offerPriceDetails={
        <>
          {!!offer.sell && (
            <PriceDetails
              currencyCode={currentCurrency}
              price={offer.sell.price}
              priceLabel={`${offer.sell.los} nights from`}
              discount={Math.round(offer.sell.discount * 100)}
              discountDisplayType="badge"
            />
          )}
        </>
      }
      productType={
        offer.promotions.length ? 'limited_time_special' : 'bedbank_hotel'
      }
      offerUrl={offerUrl}
      onClick={onCardClick}
      soldOutOfferId={offer.id}
      target={target}
    />
  )
})

export default BedbankCard
