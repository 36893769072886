import { Moment } from 'moment'
import React, { useCallback, useContext, useMemo } from 'react'

import { MessageContainer } from './common'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import GeoContext from 'contexts/geoContext'
import { ModalElementContext } from 'contexts/ModalProvider'
import { useAppSelector } from 'hooks/reduxHooks'
import { isActiveLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import CarouselCTACard from 'tripPlanner/components/Bookmark/Carousels/CarouselCTACard'
import OfferItemCarousel from 'tripPlanner/components/Bookmark/Carousels/OfferItemCarousel'
import SearchAccommodation from 'tripPlanner/components/TripModal/TripItems/Search/SearchAccommodation'
import AddCustomHotelCTA from 'tripPlanner/components/TripSummary/CallToActionButtons/Hotel/AddCustomHotelCTA'
import HotelSearchModalCTA from 'tripPlanner/components/TripSummary/CallToActionButtons/Hotel/HotelSearchModalCTA'
import { useLPPRecommendations } from 'tripPlanner/hooks/api'
import useOpenModal from 'tripPlanner/hooks/View/useOpenModal'

interface Props {
  offerId?: string
  checkIn?: Moment
  checkOut?: Moment
  tripId: string
  sectionId: string
  showSearch: boolean
}

function MoreOffersModal({
  offerId,
  checkIn,
  checkOut,
  sectionId,
  tripId,
  showSearch,
}: Props) {
  const openModal = useOpenModal()
  const modalElement = useContext<any>(ModalElementContext)
  const { currentRegionCode } = useContext(GeoContext)
  const isLuxPlusMember = useAppSelector(isActiveLuxPlusMember)

  const { data: recommendations, isLoading } = useLPPRecommendations(
    offerId,
    currentRegionCode,
    isLuxPlusMember,
    checkIn,
    checkOut,
  )

  const recommendedOfferIds = useMemo(() => {
    if (recommendations) {
      return recommendations
        .slice(0, 3)
        .map((recommendation) => recommendation.offerId)
    } else {
      return []
    }
  }, [recommendations])

  const onSearchButtonClick = useCallback(() => {
    modalElement.resolve()
    openModal(SearchAccommodation, {
      tripId,
      sectionId,
    })
  }, [modalElement, openModal, tripId, sectionId])

  const showFooterButtons =
    !isLoading && recommendedOfferIds.length === 0 && showSearch

  const buttonProps: Partial<React.ComponentProps<typeof Modal>> =
    showFooterButtons ?
        {
          onSecondaryActionClick: modalElement.resolve,
          secondaryActionText: 'Cancel',
          onPrimaryActionClick: onSearchButtonClick,
          primaryActionText: 'Search hotels',
        } :
        {}

  const carouselCtaCard = showSearch ? (
    <CarouselCTACard key="ctaCard">
      <Group direction="horizontal" gap={16}>
        <HotelSearchModalCTA tripId={tripId} onClick={modalElement.resolve} />
        <AddCustomHotelCTA tripId={tripId} onClick={modalElement.resolve} />
      </Group>
    </CarouselCTACard>
  ) : undefined

  return (
    <Modal
      title="Recommended Offers"
      onClose={modalElement.resolve}
      size="L"
      {...buttonProps}
    >
      <VerticalSpacer gap={16}>
        {isLoading && (
          <MessageContainer>
            <BodyText
              align="center"
              colour="neutral-one"
              variant="large"
              weight="bold"
            >
              Finding the best offers for you
            </BodyText>
            <LoadingIndicator inline />
          </MessageContainer>
        )}
        {!isLoading && recommendedOfferIds.length === 0 && (
          <MessageContainer>
            <BodyText align="center" colour="neutral-one" variant="large">
              Sorry, we couldn't find any recommendations for this offer
            </BodyText>
          </MessageContainer>
        )}
        {!isLoading && recommendedOfferIds.length > 0 && (
          <OfferItemCarousel
            offerIds={recommendedOfferIds}
            sectionId={sectionId}
            tripId={tripId}
            ctaCard={carouselCtaCard}
          />
        )}
      </VerticalSpacer>
    </Modal>
  )
}

export default MoreOffersModal
